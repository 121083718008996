import {
  VoteTagButton,
  VoteTagButtonVariant,
} from '@synoptic/ui-kit/vote-tag-button/vote-tag-button.js';
import {
  CommentFragmentFragment,
  PostCardFragmentFragment,
  StreamPostCardFragmentFragment,
  TournamentPostCardFragmentFragment,
  VoteType,
} from '@/__generated__/graphql.ts';
import { useUnvote, useVote } from '../use-vote.ts';

export const PostVoteButton = ({
  post,
  disabled,
  variant,
}: {
  post:
    | PostCardFragmentFragment
    | StreamPostCardFragmentFragment
    | TournamentPostCardFragmentFragment
    | CommentFragmentFragment;
  disabled?: boolean;
  variant?: VoteTagButtonVariant;
}) => {
  const [vote] = useVote();
  const [unvote] = useUnvote();

  const onVote = (type: VoteType) =>
    post.voted === type ? unvote(post.id) : vote(post.id, type);

  return (
    <VoteTagButton
      variant={variant}
      disabled={disabled}
      voted={post.voted}
      upvotes={post.upvotes}
      downvotes={post.downvotes}
      onUpvote={() => onVote(VoteType.Up)}
      onDownvote={() => onVote(VoteType.Down)}
    />
  );
};
