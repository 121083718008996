import { clsx } from 'clsx';
import { ArrowDownIcon } from '../icons/react/arrow-down';
import { ArrowUpIcon } from '../icons/react/arrow-up';
import { PostTagButton } from '../post-tag-button/post-tag-button';
import {
  voteTagButtonContainer,
  voteTagButtonIcon,
  VoteTagButtonVariant,
  voteTagButtonVariants,
} from './vote-tag-button.css';

export type { VoteTagButtonVariant };

type VoteButtonProps = {
  voted?: 'UP' | 'DOWN' | null;
  downvotes: number;
  upvotes: number;
  onUpvote?: () => void;
  onDownvote?: () => void;
  disabled?: boolean;
  variant?: VoteTagButtonVariant;
};
export const VoteTagButton: React.FC<VoteButtonProps> = ({
  voted,
  downvotes,
  upvotes,
  onUpvote,
  onDownvote,
  disabled,
  variant = 'secondary',
}) => {
  const totalVotes = upvotes + downvotes;
  const votesDiff = upvotes - downvotes;
  const downvotesRatio = downvotes ? (downvotes / totalVotes) * 100 : 0;

  return (
    <PostTagButton
      variant={variant}
      style={
        {
          '--downvotes-ratio': `${downvotesRatio}%`,
        } as React.CSSProperties
      }
      data-has-votes={totalVotes > 0 ? '' : undefined}
      className={clsx(voteTagButtonContainer, voteTagButtonVariants[variant])}
      startIcon={
        <button
          type="button"
          disabled={disabled}
          onClick={onUpvote}
          data-selected={voted === 'UP'}
          className={clsx(voteTagButtonIcon['up'])}
        >
          <ArrowUpIcon />
        </button>
      }
      endIcon={
        <button
          type="button"
          disabled={disabled}
          onClick={onDownvote}
          data-selected={voted === 'DOWN'}
          className={clsx(voteTagButtonIcon['down'])}
        >
          <ArrowDownIcon />
        </button>
      }
      asChild
    >
      <div>{votesDiff}</div>
    </PostTagButton>
  );
};
