import { ApolloClient, useApolloClient, useMutation } from '@apollo/client';
import { gql } from '@/__generated__';
import {
  UnvoteMutation,
  VoteMutation,
  VoteType,
} from '@/__generated__/graphql';
import { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions';

const RegularPostVotesFragment = gql(`
  fragment RegularPostVotesFragment on RegularPost {
    id
    upvotes
    downvotes
    voted
  }
`);

const StreamPostVotesFragment = gql(`
  fragment StreamPostVotesFragment on StreamPost {
    id
    upvotes
    downvotes
    voted
  }
`);

const TournamentPostVotesFragment = gql(`
  fragment TournamentPostVotesFragment on TournamentPost {
    id
    upvotes
    downvotes
    voted
  }
`);
const CommentPostVotesFragment = gql(`
  fragment CommentPostVotesFragment on CommentPost {
    id
    upvotes
    downvotes
    voted
  }
`);

const Vote = gql(`
mutation Vote($postId: ID!, $type: VoteType!) {
  vote(id: $postId, vote: $type) {
    ... on RegularPost {
      ...RegularPostVotesFragment
    }
    ... on StreamPost {
      ...StreamPostVotesFragment
    }
    ... on TournamentPost {
      ...TournamentPostVotesFragment
    }
    ... on CommentPost {
      ...CommentPostVotesFragment
    }
  }
}
`);

const getPrevPost = (apolloClient: ApolloClient<object>, postId: string) => {
  // TODO: get rid of reading all fragments separately. Probably prop update func
  const prevRegularPost = apolloClient.readFragment({
    id: apolloClient.cache.identify({
      id: postId,
      __typename: 'RegularPost',
    }),
    fragment: RegularPostVotesFragment,
  });

  const prevStreamPost = apolloClient.readFragment({
    id: apolloClient.cache.identify({
      id: postId,
      __typename: 'StreamPost',
    }),
    fragment: StreamPostVotesFragment,
  });

  const prevTournamentPost = apolloClient.readFragment({
    id: apolloClient.cache.identify({
      id: postId,
      __typename: 'TournamentPost',
    }),
    fragment: TournamentPostVotesFragment,
  });

  const prevCommentPost = apolloClient.readFragment({
    id: apolloClient.cache.identify({
      id: postId,
      __typename: 'CommentPost',
    }),
    fragment: CommentPostVotesFragment,
  });

  return (
    prevRegularPost || prevStreamPost || prevTournamentPost || prevCommentPost
  );
};

export const useVote = () => {
  const apolloClient = useApolloClient();

  const [voteMutation, result] = useMutation(Vote);

  const vote = (postId: string, type: VoteType) => {
    let optimisticResponse: MutationBaseOptions<VoteMutation>['optimisticResponse'] =
      undefined;

    const prev = getPrevPost(apolloClient, postId);

    if (prev && type !== prev.voted) {
      let downvotes: number;
      let upvotes: number;

      if (type === VoteType.Up) {
        downvotes =
          prev.voted === VoteType.Down ? prev.downvotes - 1 : prev.downvotes;
        upvotes = prev.upvotes + 1;
      } else {
        downvotes = prev.downvotes + 1;
        upvotes = prev.voted === VoteType.Up ? prev.upvotes - 1 : prev.upvotes;
      }

      optimisticResponse = {
        vote: {
          __typename: prev.__typename,
          id: postId,
          downvotes,
          upvotes,
          voted: type,
        },
      };
    }

    return voteMutation({
      variables: { postId, type },
      optimisticResponse,
    });
  };

  return [vote, result] as const;
};

const Unvote = gql(`
  mutation Unvote($postId: ID!) {
    unvote(id: $postId) {
      ... on RegularPost {
        ...RegularPostVotesFragment
      }
      ... on StreamPost {
        ...StreamPostVotesFragment
      }
      ... on TournamentPost {
        ...TournamentPostVotesFragment
      }
      ... on CommentPost {
        ...CommentPostVotesFragment
      }
    }
  }
`);

export const useUnvote = () => {
  const apolloClient = useApolloClient();
  const [unvoteMutation, result] = useMutation(Unvote);

  const unvote = (postId: string) => {
    const prev = getPrevPost(apolloClient, postId);

    let optimisticResponse: MutationBaseOptions<UnvoteMutation>['optimisticResponse'] =
      undefined;

    if (prev) {
      optimisticResponse = {
        unvote: {
          __typename: prev.__typename,
          id: postId,
          upvotes: prev.voted === VoteType.Up ? prev.upvotes - 1 : prev.upvotes,
          downvotes:
            prev.voted === VoteType.Down ? prev.downvotes - 1 : prev.downvotes,
          voted: null,
        },
      };
    }

    return unvoteMutation({
      variables: { postId },
      optimisticResponse,
    });
  };

  return [unvote, result] as const;
};
